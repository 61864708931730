<template>
  <ClientOnly>
    <div v-if="isMounted" class="casino-tabs">
      <div class="casino-top-tabs">
        <NuxtI18nLink
          v-for="tab in topTabs"
          :key="tab.link"
          :ref="(el) => (tabRefs[tab.link] = el as ComponentPublicInstance)"
          class="casino-top-tab"
          :data-t="`casino-top-tab-${tab.link.split('/').pop()}-4xfh`"
          :class="{ active: tab.isActive }"
          :to="tab.link"
          prefetch
        >
          {{ tab.name }}
        </NuxtI18nLink>
      </div>
      <div
        v-if="bottomTabs.length"
        ref="tabsContainer"
        class="casino-bottom-tabs"
      >
        <NuxtI18nLink
          v-for="bottomTab in bottomTabs"
          :key="bottomTab.link"
          :ref="
            (el) =>
              (tabBottomRefs[bottomTab.link] = el as ComponentPublicInstance)
          "
          class="casino-bottom-tab"
          :data-t="`casino-bottom-tab-${bottomTab.link.split('/').pop()}-9gt9`"
          active-class="active"
          :to="bottomTab.link"
          prefetch
        >
          <StIcon
            v-if="bottomTab.iconLink"
            :icon-link="bottomTab.iconLink"
            class="casino-bottom-icon"
            :size="24"
          />
          <StIcon v-else :name="bottomTab.icon" :size="24" />
          <span class="casino-bottom-tab-name">{{ bottomTab.name }}</span>
        </NuxtI18nLink>
      </div>
    </div>
    <div v-else>
      <StSkeletonLoader
        bg-color="var(--background-primary)"
        height="32px"
        width="100%"
        :shimmer-opacity="0.25"
        class="skeleton"
      />
    </div>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { delay } from '@st/utils'
import { useCasinoTabs } from './useCasinoTabs'

const { topTabs, bottomTabs } = useCasinoTabs({ isLobbyEnabled: true })

const tabRefs = ref<Record<string, ComponentPublicInstance | null>>({})
const activeTab = computed(() => topTabs.value.find((tab) => tab.isActive))
const activeTabElement = computed(() =>
  activeTab.value ? tabRefs.value[activeTab.value.link] : null,
)

const tabBottomRefs = ref<Record<string, ComponentPublicInstance | null>>({})
const activeBottomTab = computed(() =>
  bottomTabs.value.find((tab) => tab.isActive),
)
const activeBottomTabElement = computed(() =>
  activeBottomTab.value
    ? tabBottomRefs.value[activeBottomTab.value?.link]
    : null,
)

function scrollIntoView() {
  if (activeTabElement.value) {
    activeTabElement.value.$el?.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'nearest',
    })
  }
  if (activeBottomTabElement.value) {
    activeBottomTabElement.value.$el?.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'nearest',
    })
  }
}

watch(
  [activeTabElement, activeBottomTab],
  async () => {
    await delay(300)
    scrollIntoView()
  },
  {
    immediate: true,
  },
)
const { isMounted } = useIsOnMounted()
</script>

<style scoped>
.casino-tabs {
  position: sticky;
  z-index: 11;
  top: 44px;

  background-color: var(--background-base);

  transition: transform 0.2s;
}

.casino-bottom-tabs {
  scrollbar-width: none;

  position: relative;

  overflow: scroll hidden;
  display: flex;

  padding: var(--spacing-050) var(--spacing-100);

  list-style-type: none;

  background-color: var(--background-primary);
  border-bottom: var(--border-width-light) solid
    var(--border-primary, rgb(255 255 255 / 12%));
  border-radius: var(--border-radius-200, 16px) var(--border-radius-200, 16px) 0
    0;

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & .casino-bottom-tab {
    transform: scale(0.9);

    display: flex;
    flex-direction: column;
    gap: var(--spacing-050);
    place-items: center;

    padding: var(--spacing-025) var(--spacing-125);

    color: var(--text-secondary);
    text-decoration: none;
  }

  & .active {
    color: var(--text-primary);
  }
}

.casino-bottom-tab-name {
  min-width: 60px;
  height: 16px;

  font: var(--mobile-text-medium);
  text-align: center;
  white-space: nowrap;
}

.casino-top-tabs {
  scrollbar-width: none;

  overflow: scroll hidden;
  display: flex;

  padding: var(--spacing-100);

  font: var(--mobile-title-2-semibold);
  color: var(--text-secondary);

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.casino-top-tab {
  padding: var(--spacing-050) var(--spacing-100);
  color: var(--text-secondary);
  text-decoration: none;
  white-space: nowrap;

  &.active {
    color: var(--text-primary);
  }
}

.skeleton {
  height: 44px;
  background-color: var(--background-base);
}

.casino-bottom-icon {
  width: 24px;
  height: 24px;
}
</style>
